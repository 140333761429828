import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  ISocialSignIn,
  checkAppleSignIn,
  checkFacebookSignIn,
  checkGoogleSignIn,
  initialValuesLogin,
  loginApi,
  signInWithApple,
  signInWithFacebook,
  signInWithGoogle,
  validationSchemaLogin,
} from "./LoginControllers";
import useTranslationHook from "../../../hooks/UseTranslationHook";
import { localStorageUtils } from "../../../utils/LocalStorageUtil";
import {
  appleIcon,
  facebookIcon,
  googleIcon,
} from "../../../assets/icons/SvgIconList";
import ICTextInput from "../../../core-component/ICTextInput";
import ICDropDown from "../../../core-component/ICDropDown";
import { commonRoute } from "../../../routes/RouteCommon";
import ICImage from "../../../core-component/ICImage";
import { handleNumericInput } from "../../../utils/AppFunctions";
import ICCommonModal from "../../../components/common/ICCommonModel";
import {
  fetchContentManagementApi,
  IAppContent,
} from "../../about/AboutController";
import { APP_CONTENT_TYPE } from "../../../utils/AppEnumerations";
import CustomEditor from "../../product-services/Web/CustomEditor";

const LoginView = () => {
  const { t } = useTranslationHook();
  const navigation = useNavigate();
  const [mobileNo, setMobileNo] = useState<number | undefined>();
  const [loading, setLoading] = useState(false);
  const [showTermsAndCondition, setShowTermsAndCondition] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<number | null>(1);
  const [isSocialSignIn, setIsSocialSignIn] = useState<ISocialSignIn>();
  const [terms, setTerms] = useState<IAppContent>();
  const [privacy, setPrivacy] = useState<IAppContent>();
  const location = useLocation();

  useEffect(() => {
    const fetchStoredUserName = async () => {
      const storedUserName = await Number(localStorageUtils.getUserName());
      if (storedUserName !== 0) {
        setMobileNo(storedUserName);
      }
    };
    fetchStoredUserName();
    fetchContentManagementApi(setPrivacy, APP_CONTENT_TYPE.PrivacyPolicy);
    fetchContentManagementApi(setTerms, APP_CONTENT_TYPE.TermsOfService);
  }, []);

  const validationSchema = validationSchemaLogin(t);
  const formikInitialValues = {
    ...initialValuesLogin,
    username: mobileNo,
  };

  const formik = useFormik({
    initialValues: formikInitialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      loginApi(values, navigation, setLoading);
    },
  });

  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
    const fetchData = () => {
      checkGoogleSignIn(setIsSocialSignIn);
      checkFacebookSignIn(setIsSocialSignIn);
      checkAppleSignIn(setIsSocialSignIn);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      // Prevent back navigation
      window.history.pushState(null, "", location.pathname);
      event.preventDefault();
    };

    // Add a dummy state to the history stack
    window.history.pushState(null, "", location.pathname);

    // Add event listener for popstate
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [location]);

  return (
    <div className="h-full">
      <div className="grid grid-cols-1 xl:grid-cols-2 h-full">
        <div className={`relative`}>
          <ICImage
            src={require("../../../assets/image/login.jpeg")}
            className="h-full object-cover"
          />
        </div>

        <div className="comman-padding flex flex-col items-center justify-center">
          <div className="text-center  mb-5">
            <p className="font-bold comman-text">{t("hello_there")}</p>
            <p>{t("get_start")}</p>
            <p>{t("otp_on_your_mobile_number")}</p>
          </div>
          <form className="mt-5" onSubmit={formik.handleSubmit}>
            <div className="relative mb-6">
              <div className="flex justify-center space-x-3">
                <ICDropDown
                  label={t("country")}
                  borderNone
                  selected={selectedCountry ? selectedCountry : undefined}
                  options={[
                    {
                      label: "+91",
                      value: 1,
                    },
                  ]}
                  textSpacing="mobile-number-spacing"
                  className={`${
                    selectedCountry ? "!w-[60px]" : "!w-[80px]"
                  } !mt-[5.5px]  `}
                  onSelect={(option) => setSelectedCountry(option.value)}
                />

                <ICTextInput
                  type="text"
                  inputMode="numeric"
                  textClass="mobile-number-spacing"
                  autoFocus
                  placeholder={t("mobile_number")}
                  name="username"
                  value={formik.values.username}
                  onChange={(event) => {
                    handleNumericInput(event);
                    formik.setFieldValue(
                      "username",
                      event.target.value
                        ? Number(event.target.value)
                        : undefined
                    );
                  }}
                  onBlur={formik.handleBlur}
                  errorMessage={
                    formik.touched?.username
                      ? formik.errors.username
                      : undefined
                  }
                  borderNone
                />
              </div>
            </div>
            {isSocialSignIn?.appleIs === "1" ||
            isSocialSignIn?.facebookIs === "1" ||
            isSocialSignIn?.googleIs === "1" ? (
              <div>
                <div className="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t  after:mt-0.5 after:flex-1 after:border-t ">
                  <p className="mx-4 mb-0 text-center font-semibold text-skin-login-label-light">
                    {t("Or")}
                  </p>
                </div>
                <div className="flex space-x-5  justify-center">
                  {isSocialSignIn?.googleIs === "1" && (
                    <div
                      className="cursor-pointer "
                      onClick={() => signInWithGoogle(navigation)}
                    >
                      <div className="h-8 w-8 border border-1 rounded-2xl p-1">
                        {googleIcon}
                      </div>
                    </div>
                  )}
                  {isSocialSignIn?.facebookIs === "1" && (
                    <div
                      className="cursor-pointer"
                      onClick={signInWithFacebook}
                    >
                      <div className="h-7 w-7">{facebookIcon}</div>
                    </div>
                  )}
                  {isSocialSignIn?.appleIs === "1" && (
                    <div className="cursor-pointer" onClick={signInWithApple}>
                      <div className="h-8 w-8 border border-1 rounded-2xl p-1">
                        {appleIcon}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <span></span>
            )}
            <div className="text-center mt-3">
              <div className="sm:flex !text-[12px]">
                <div className="text-nowrap w-full text-center">
                  {" "}
                  {t("agree_info")}&nbsp;
                </div>
                <div className="flex justify-center items-end cursor">
                  <div
                    onClick={() => {
                      setShowTermsAndCondition(true);
                      setShowPrivacyPolicy(false);
                    }}
                    className="text-blue-600 cursor !text-[11px] pt-[0.5px] text-nowrap text-center"
                  >
                    {t("terms_and_condition")},
                  </div>
                  <div
                    onClick={() => {
                      setShowTermsAndCondition(false);
                      setShowPrivacyPolicy(true);
                    }}
                    className="cursor text-blue-600 cursor !text-[11px] pt-[0.5px] text-nowrap "
                  >
                    &nbsp;{t("privacy_policy")}
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-6 flex items-center justify-between"></div>
            <div className="text-center lg:text-left flex justify-center">
              <button
                children={t("log_in")}
                className={`w-full ${loading ? "cursor-not-allowed " : ""} ${
                  formik.values.username === undefined
                    ? "comman-disablebtn cursor-not-allowed auth-padding text-white"
                    : ""
                } ${
                  !formik.isValid
                    ? "cursor-not-allowed comman-disablebtn auth-padding  text-white"
                    : "auth-btn"
                }`}
                data-te-ripple-init
                data-te-ripple-color="light"
                disabled={
                  !formik.isValid ||
                  formik.values.username === undefined ||
                  loading
                }
              />
            </div>
          </form>
        </div>
        {showTermsAndCondition && (
          <ICCommonModal
            title={t(terms?.type ? terms?.type : "")}
            content={
              <div className="comman-grey flex-1 overflow-y-scroll remove-scrollbar-width">
                <p>
                  <CustomEditor serviceDesc={terms?.value} />
                </p>
              </div>
            }
            isModalShow={showTermsAndCondition}
            setIsModalShow={setShowTermsAndCondition}
          />
        )}
        {showPrivacyPolicy && (
          <ICCommonModal
            title={t(privacy?.type ? privacy?.type : "")}
            content={
              <div className="comman-grey flex-1 overflow-y-scroll remove-scrollbar-width">
                <p>
                  <CustomEditor serviceDesc={privacy?.value} />
                </p>
              </div>
            }
            isModalShow={showPrivacyPolicy}
            setIsModalShow={setShowPrivacyPolicy}
          />
        )}
      </div>
    </div>
  );
};

export default LoginView;
