import React from "react";

const VerticalBuffer = () => {
  return (
    <div>
      <div>
        <img src={require("../../assets/image/loader-1.gif")} alt="Loading" />
      </div>
    </div>
  );
};

export default VerticalBuffer;
