import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import useTranslationHook from "../../../hooks/UseTranslationHook";
import ICOtpInput from "../../../core-component/ICOtpInput";
import { initialValuesOtp, submitOtpApi } from "./OtpController";
import { loginApi } from "../login/LoginControllers";
import Timer from "../../../core-component/ICTimer";
import { editIcon } from "../../../assets/icons/SvgIconList";
import { localStorageUtils } from "../../../utils/LocalStorageUtil";
import ICButton from "../../../core-component/ICButton";
import ICImage from "../../../core-component/ICImage";

const VerifyOTP = () => {
  const { t } = useTranslationHook();
  const navigation = useNavigate();
  const location = useLocation();
  const mobileNo = location?.state?.username;

  const [otpValue, setOtpValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOtpChange = (otp: string) => {
    setOtpValue(otp);
  };

  const formik = useFormik({
    initialValues: initialValuesOtp,
    onSubmit: async (values) => {
      submitOtpApi(
        { ...values, otp: otpValue, username: mobileNo },
        navigation,
        setLoading,
        t
      );
      setOtpValue("");
      localStorageUtils.removeAll();
    },
  });

  function ResendOTP() {
    loginApi({ username: mobileNo }, navigation, setLoading);
    setOtpValue("");
  }
  const handleBackToLogin = () => {
    localStorageUtils.setUserName(mobileNo);
    navigation(-1); // Pass mobile number as state
  };
  return (
    <section className="container mx-auto h-full">
      <div className="h-full">
        <div className="flex h-full flex-wrap items-center justify-center xl:justify-between">
          <div className="shrink-1 grow-0 basis-auto md:mb-0 md:w-9/12 md:shrink-0  xl:w-6/12">
            <ICImage
              src={require("../../../assets/image/vp-otp-verification.png")}
              className="w-full"
            />
          </div>

          <div className="md:mb-0 md:w-8/12 lg:w-5/12 xl:w-5/12">
            <div className="text-center mt-5">
              <p className="font-bold comman-text text-3xl">
                {t("otp_verification")}
              </p>
              <p className="comman-grey">{t("otp_on_your_mobile_number")}</p>
            </div>
            <div className="flex mt-3 items-center justify-center text-center">
              <p className="mx-5">+91 {mobileNo}</p>
              <p className="cursor w-6 h-6" onClick={handleBackToLogin}>
                {editIcon}
              </p>
            </div>
            <form className="mt-5" onSubmit={formik.handleSubmit}>
              <div className="relative mb-4 flex items-center justify-center">
                <ICOtpInput
                  value={otpValue}
                  onChange={handleOtpChange}
                  numInputs={4}
                />
              </div>

              <div className="mb-2 flex justify-center">
                <Timer initialTime="0:30" maxResends={3} onResend={ResendOTP} />
              </div>
              <div className="text-center lg:text-left">
                <button
                  type="submit"
                  children={t("verify_and_continue")}
                  className={`!w-full  ${
                    otpValue.length !== 4
                      ? "cursor-not-allowed comman-disablebtn auth-padding  text-white"
                      : "auth-btn"
                  }`}
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  disabled={otpValue.length !== 4}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerifyOTP;
