import { serviceMaker } from "../..";
import { API_ENDPOINTS } from "../../../utils/ApiEndPoint";

export const GET_SERVICE_BY_ID = (id: number) =>
  serviceMaker(
    API_ENDPOINTS.get_all_service.url + "/" + id,
    API_ENDPOINTS.get_all_service.method
  );

export const GET_ALL_SERVICE_CATEGORY_FOR_TREND = (heading?: string) =>
  serviceMaker(
    API_ENDPOINTS.get_all_service.url +
      `${heading ? `?type=T&all=true` : `?type=T`}`,
    API_ENDPOINTS.get_all_service.method
  );
export const GET_ALL_SERVICE_CATEGORY_FOR_DISCOVER = (heading?: string) =>
  serviceMaker(
    API_ENDPOINTS.get_all_service.url +
      `${heading ? `?type=D&all=true` : `?type=D`}`,
    API_ENDPOINTS.get_all_service.method
  );

export const GET_ALL_SERVICE = () =>
  serviceMaker(
    API_ENDPOINTS.get_all_service.url,
    API_ENDPOINTS.get_all_service.method
  );
