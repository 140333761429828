import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { navigationApp } from "../../utils/AppFunctions";
import ICButton from "../../core-component/ICButton";
import UseTranslationHook from "../../hooks/UseTranslationHook";
import { publicRoute } from "../../routes/RoutePublic";
import { PUBLIC_TABS } from "../../utils/AppEnumerations";
import UseMobileLayoutHook from "../../hooks/UseMobileLayoutHook";
import ICImage from "../../core-component/ICImage";
const Welcome = () => {
  const navigate = useNavigate();
  const { isMobile } = UseMobileLayoutHook();
  const { t } = UseTranslationHook();
  useEffect(() => {
    document.title = PUBLIC_TABS.VProsper;
  }, []);

  return (
    <div className="h-full">
      <div className="grid grid-cols-1 lg:grid-cols-2 h-full">
        <div className={`relative`}>
          <ICImage
            src={require("../../assets/image/application.png")}
            className="h-full object-cover"
          />
        </div>

        <div className="comman-padding flex flex-col items-center justify-center">
          <div className="text-center">
            <p className="trainer-font">{t("you_are")}</p>
            <p className="trainer-font">{t("architect_of")}</p>
            <p className="trainer-font">{t("your_own_wellness")}</p>
          </div>
          <div className="w-full top comman-padding">
            <button
              type="button"
              onClick={() => navigationApp(navigate, "/" + publicRoute.login)}
              className="auth-btn cursor"
            >
              {t("start_your_journey")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
