import { serviceMaker } from "..";

import { API_ENDPOINTS } from "../../utils/ApiEndPoint";
import { encryptData } from "../../utils/AppFunctions";

export const LOGIN = (payload: { username: string }) =>
  serviceMaker(API_ENDPOINTS.login.url, API_ENDPOINTS.login.method, {payload:encryptData(JSON.stringify(payload))});

export const VERIFY_OTP = (payload: { username?: string; otp: string }) =>
  serviceMaker(
    API_ENDPOINTS.verify_otp.url,
    API_ENDPOINTS.verify_otp.method,
    {payload: encryptData(JSON.stringify(payload))}
  );

export const LOGOUT = (payload: string) =>
  serviceMaker(API_ENDPOINTS.logout.url, API_ENDPOINTS.logout.method, payload);
