export const commonRoute = {
  helpCenter: `help-center`,
  helpTopic: `help-topic`,
  helpTopicDetails: `help-topic-details`,
  preference: `preference`,
  themePreference: `theme-preference`,
  grievance: `grievance`,
  grievanceConversation: `grievance-conversation`,
  addUpdateGrievance: `add-update-grievance`,
  about: "about",
  privacyPolicy: "privacy-policy",
  termsConditions: "terms-conditions",
  library: `library`,
  premiumPackages: `premium-packages`,
  libraryAll: `library-all`,
  community: `post`,
  postDetails: `post-details`,
  subscriptions: `subscriptions`,
  transaction: `transaction`,
  transactionDetails: `transaction-details`,
  favorites: `favorites`,
  folderDetail: `folder-detail`,
  libraryDetails: `library-details`,
  inviteFriends: `invite-friends`,
  generalFaqs: `general-faqs`,
};
